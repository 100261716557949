export const environment = {
  production: true,
  URL: 'https://api.dev.kakoo.app/',
  BACKET_URL: 'https://kakoo.syd1.digitaloceanspaces.com/',
  GOOGLE_CLIENT_ID:
    '708762911938-luv5f9qbkk433jfb5r9nute0gkuerp44.apps.googleusercontent.com',
  GOOGLE_CLIENT_SECRET: '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',
  stripeKey: "pk_test_51P49ZhRrFaWEr20OpNVjtWaHevt8uH0Hx2E3X9gtzDZFgqK6BTdk5WMfkpanH7viPsKiazSL4f8xR3YPAMulhok000nDMrwTqJ",
  agoraAppKey: "611129114#1325176",
  agoraAppID: "e1013b1ec6ee41f7aa62c7d842b8ddb0",
  veriffApiKey: '04457317-f60f-4d27-9a97-5f3256ca0aa6',
  oneSignalKey: "24cb4484-3bf6-4937-a2ad-b7ed1e2ce699",
  notify_port: 8783,
};
