import { ApplicationConfig, isDevMode, importProvidersFrom, APP_INITIALIZER } from '@angular/core';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { ApiService } from './api.service';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { UserInterceptor } from './shared/interceptor/api.interceptor';
import { AuthService } from './login/service/auth.service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { register } from 'swiper/element/bundle';
import { provideNgxStripe } from 'ngx-stripe';
// import { IMqttServiceOptions, MQTT_SERVICE_OPTIONS, MqttModule, MqttService } from 'ngx-mqtt';
import { environment } from '../environments/environment';
import { provideAnimations } from '@angular/platform-browser/animations';

register();

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, inMemoryScrollingFeature),
    provideServiceWorker('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerImmediately',
    }),
    ApiService,
    AuthService,
    provideHttpClient(withInterceptors([UserInterceptor])),
    provideAnimationsAsync(),
    importProvidersFrom(MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatIconModule),
    provideNgxStripe(environment.stripeKey),
    provideAnimations()
  ],
};
