import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guadrs/unauthorized.guard';
import { AnAuthGuard } from './shared/guadrs/authorized.guard';

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./login/modules/login.module').then((m) => m.LoginModule),
    canActivate: [AnAuthGuard],
  },
  {
    path: 'download-info',
    loadChildren: () =>
      import('./info-page/modules/info-page.module').then(
        (m) => m.InfoPageModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./privacy/modules/privacy.module').then(
        (m) => m.PrivacyModule
      ),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () =>
      import('./terms/modules/terms.module').then(
        (m) => m.TermsModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./layout/modules/layout.module').then((m) => m.LayoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];
